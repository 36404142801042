import gql from 'graphql-tag';
import {
  ALL_ITEM_IMAGES,
  AUTH_STATION_ITEM_ON_ITEM,
  AVAILABLE_LOCATIONS,
  BRAND_AND_MODEL_ON_ITEM,
  CARD_VALUES_ON_ITEM,
  DESCRIPTION_ON_EBAY_ITEM,
  DESCRIPTION_ON_EBAY_ORDER,
  FAILED_STATION_ITEMS_ON_ITEMS,
  IMAGE_URLS_ON_EBAY_ITEM,
  IMAGE_URLS_ON_EBAY_ORDER,
  INPUT_VALUE_FRAGMENT,
  ITEM_CONDITION_ON_EBAY_ITEM,
  ITEM_CONDITION_ON_EBAY_ORDER,
  ITEM_VALUES_AND_PARAMETERS_ON_ITEM,
  ITEM_VALUES_AND_PARAMETERS_ON_ORDER,
  NOT_AUTHENTIC_LOCATION,
  ORDER_ITEMS,
  PACKAGING_COLLATERALS_ON_ITEM,
  PARTNER_LOCATION_CONFIGURATIONS,
  PSNAD_EXCEPTION,
  QC_STATION_ITEM_ON_ITEM,
  RELATED_RETURN_ITEMS,
  RETURN_ON_EBAY_ITEM,
  SHIPMENT_BOX_SCAN_ITEM_BY_LPN,
  SHIPMENT_ON_ORDER,
  SHIPMENT_ON_SHIPMENT_BOX,
  STATION_ITEM_FOR_TIMELINE_ON_ITEM,
  SUPERVISED_AUTHENTICATION_ON_EBAY_ORDER,
  TITLE_ON_EBAY_ITEM,
  TITLE_ON_EBAY_ORDER,
  VaultShipmentBoxOnItem,
  WHITE_GLOVE_ON_EBAY_ORDER,
} from './fragments';

export const SERIAL_NUMBER_DUP = gql`
  mutation serialNumberExisting($serialNumber: String!) {
    serialNumberExisting(serialNumber: $serialNumber)
  }
`;

export const SCAN_ORDER_BY_TRACKING_OR_EVTN = gql`
  ${ITEM_VALUES_AND_PARAMETERS_ON_ORDER}
  ${ITEM_VALUES_AND_PARAMETERS_ON_ITEM}
  mutation scanOrderByTrackingOrEvtn(
    $identificationString: String!
    $partnerLocationId: String
    $stationId: ID!
  ) {
    scanOrderByTrackingOrEvtn(
      identificationString: $identificationString
      stationId: $stationId
      partnerLocationId: $partnerLocationId
    ) {
      order {
        id
        status
        evtn
        itemQuantity
        orderSportItemValue
        imageUrls
        unreceivedTrackings
        isReturn
        isCancelledOrder
        isVaultBound
        isInHandSubmission
        isExport
        exportCountry
        isLiveEvent
        gradedListingTc
        cardType
        isCardTypeConfigOn
        orderExceptions {
          id
          exceptionVerdict
          createdBy {
            id
            userIdentification
          }
          exceptionCode {
            id
            name
            displayName
          }
          createdAt
          notes {
            id
            description
            firstName
            lastName
            userIdentification
            createdAt
            filesInfo {
              filename
              url
            }
          }
        }
        trackings {
          id
          trackingNumber
        }
        items {
          id
          alitheonStatus
          itemBranded
          itemCertificationNumber
          displayTitle
          isExport
          exportCountry
          isInHandSubmission
          isConfirmReceived
          liveEventIntakeStatus
          originalAuthenticator
          imagingAuthenticator {
            id
            status
            authenticator {
              id
              name
            }
          }
          allItemImages {
            isAlitheon
            imagePill
            imageUrl
            title
          }
          authenticityStatus
          ...ItemValuesAndParameters
          originalItem {
            id
            lpn {
              id
              lpn
            }
          }
          ebayItem {
            id
            unitId
            imageUrls
            itemUrl
            authenticationLevel
            psaMode
            signatureRequired
          }
          collaterals {
            id
            name
            lpnId
            itemId
            lpn {
              lpn
            }
          }
          lpn {
            id
            lpn
            createdAt
          }
          name
          category {
            id
            name
            hasCollaterals
          }
          itemImages {
            id
            zoomUrl
            caption
            itemImageType
            imageUrl
          }
          order {
            id
            evtn
            imageUrls
            ...OrderItemValuesAndParameters
            ebayOrder {
              id
              title
              itemCondition
              itemUrl
              imageUrls
              authenticationLevel
            }
          }
          stationItems {
            id
            grade
            status
            station {
              id
              name
            }
          }
          createdAt
          updatedAt
        }
        ebayOrder {
          id
          title
          itemCondition
          itemUrl
          imageUrls
          authenticationLevel
        }
        partnerLocation {
          id
          timeZone
        }
      }
      readOnlyStation {
        readOnly
        message
        timestamp
      }
      readOnlyException {
        exceptionAllowed
        message
      }
    }
  }
`;

export const SCAN_ORDER_BY_EVTN = gql`
  ${ITEM_VALUES_AND_PARAMETERS_ON_ORDER}
  ${ITEM_VALUES_AND_PARAMETERS_ON_ITEM}
  mutation scanOrderByEvtn(
    $evtn: String!
    $partnerLocationId: String
    $stationId: ID!
    $bagId: ID
  ) {
    scanOrderByEvtn(
      evtn: $evtn
      stationId: $stationId
      partnerLocationId: $partnerLocationId
      bagId: $bagId
    ) {
      order {
        id
        status
        evtn
        itemQuantity
        imageUrls
        isReturn
        isCancelledOrder
        isVaultBound
        isExport
        exportCountry
        isInHandSubmission
        orderPriceCurrency
        totalAcceptedItemsPrice
        totalAcceptedItems
        isLiveEvent
        updatedAt
        nfcTag {
          id
        }
        items {
          id
          alitheonStatus
          itemBranded
          itemCertificationNumber
          displayTitle
          isExport
          exportCountry
          isInHandSubmission
          isLiveEvent
          isConfirmReceived
          isLiveEventIntakeConfirmed
          allItemImages {
            isAlitheon
            imageUrl
            imagePill
            title
          }
          ...ItemValuesAndParameters
          ebayItem {
            id
            unitId
            imageUrls
            itemUrl
            authenticationLevel
            psaMode
            signatureRequired
            itemPriceCurrency
          }
          lpn {
            id
            lpn
            createdAt
          }
          name
          category {
            id
            name
            hasCollaterals
          }
          itemImages {
            id
            zoomUrl
            caption
            itemImageType
            imageUrl
          }
          order {
            id
            evtn
            imageUrls
            ...OrderItemValuesAndParameters
            partnerLocation {
              timeZone
            }
            ebayOrder {
              id
              title
              itemCondition
              itemUrl
              imageUrls
              authenticationLevel
            }
          }
          stationItems {
            id
            grade
            status
            completedAt
            station {
              id
              name
            }
          }
          createdAt
          updatedAt
        }
        ebayOrder {
          id
          title
          itemCondition
          itemUrl
          imageUrls
          authenticationLevel
        }
        partnerLocation {
          id
          timeZone
        }
      }
      readOnlyStation {
        readOnly
        message
        timestamp
      }
      readOnlyException {
        exceptionAllowed
        message
      }
    }
  }
`;

export const DELETE_SUBMITTED_ORDERS = gql`
  mutation deleteSubmittedOrders($orderIds: [ID!]!, $bagId: ID!) {
    deleteSubmittedOrders(orderIds: $orderIds, bagId: $bagId) {
      id
      orders {
        id
        evtn
        itemQuantity
        orderPriceCurrency
        totalAcceptedItemsPrice
        totalAcceptedItems
        updatedAt
      }
    }
  }
`;

export const DELETE_SHIPMENT_BAG = gql`
  mutation deleteShipmentBag($bagId: ID!) {
    deleteShipmentBag(bagId: $bagId)
  }
`;

export const MOVE_SHIPMENT_BAG_ORDER = gql`
  mutation moveShipmentBagOrder($evtn: String!, $newBagId: ID!) {
    moveShipmentBagOrder(evtn: $evtn, newBagId: $newBagId) {
      id
      orders {
        id
        evtn
        itemQuantity
        orderPriceCurrency
        totalAcceptedItemsPrice
        totalAcceptedItems
        updatedAt
      }
    }
  }
`;

export const LIVE_EVENT_SHIPPING = gql`
  mutation liveEventShipping($liveEventId: ID!, $bagId: ID!) {
    liveEventShipping(liveEventId: $liveEventId, bagId: $bagId) {
      id
      trackingNumber
      shipped
      totalShipmentBagPrice
      totalShipmentBagCards
      shippingLabels {
        id
        zplString
      }
    }
  }
`;

export const ADD_LPN_TO_ITEM = gql`
  mutation addLpnToItem($lpn: String!, $id: ID!) {
    addLpnToItem(lpn: $lpn, id: $id) {
      id
      alitheonStatus
      lpn {
        id
        lpn
      }
    }
  }
`;

export const SHIP_VAULT_SHIPMENT_BOX = gql`
  ${VaultShipmentBoxOnItem}
  mutation shipVaultShipmentBox(
    $shipmentBoxId: ID!
    $skipShipValidation: Boolean
    $binHasBeenCollect: Boolean
  ) {
    shipVaultShipmentBox(
      shipmentBoxId: $shipmentBoxId
      skipShipValidation: $skipShipValidation
      binHasBeenCollect: $binHasBeenCollect
    ) {
      ...vaultShipmentBoxOnItem
    }
  }
`;

export const TRANSFER_ITEM_TO_PSA = gql`
  mutation transferItemToPsa($itemId: ID!) {
    transferItemToPsa(itemId: $itemId) {
      id
    }
  }
`;
export const VAULT_BOUND_SHIPMENT_BOX = gql`
  ${VaultShipmentBoxOnItem}
  mutation vaultBoundShipmentBox(
    $itemLpn: String!
    $shipmentBoxLpn: String!
    $boxId: ID!
  ) {
    vaultBoundShipmentBox(
      itemLpn: $itemLpn
      shipmentBoxLpn: $shipmentBoxLpn
      boxId: $boxId
    ) {
      ...vaultShipmentBoxOnItem
    }
  }
`;
export const CREATE_BOX_LPN = gql`
  mutation createBoxLpn($partnerLocationId: ID!, $itemId: ID) {
    createBoxLpn(partnerLocationId: $partnerLocationId, itemId: $itemId) {
      id
      lpn
    }
  }
`;
export const CREATE_LPNS = gql`
  mutation createLpns($itemId: ID!, $unitId: String) {
    createLpns(itemId: $itemId, unitId: $unitId) {
      id
      lpn
      createdAt
      item {
        id
      }
    }
  }
`;
export const MUTATE_SHIPMENT = gql`
  ${CARD_VALUES_ON_ITEM}
  mutation mutateShipment($input: ShipmentInput!) {
    mutateShipment(input: $input) {
      id
      allShipped
      deliveryDate
      signatureRequired
      address {
        id
        streetLine1
        streetLine2
        city
        stateProvinceCode
        country
        postalCode
      }
      carrier {
        id
        name
        image
      }
      order {
        id
        items {
          id
        }
        ebayOrder {
          id
          signatureRequired
        }
      }
      shipmentBoxes {
        id
        trackingNumber
        shippingLabels {
          id
          zplString
          isPhysical
        }
        box {
          id
          boxSize
        }
        dustBag {
          id
          dustBagSize
        }
        items {
          id
          ebayStatus
          ...CardValues
          lpn {
            lpn
          }
        }
      }
    }
  }
`;
export const SCAN_ITEM_BY_LPN = gql`
  ${AVAILABLE_LOCATIONS}
  ${NOT_AUTHENTIC_LOCATION}
  ${BRAND_AND_MODEL_ON_ITEM}
  ${CARD_VALUES_ON_ITEM}
  ${STATION_ITEM_FOR_TIMELINE_ON_ITEM}
  ${ITEM_VALUES_AND_PARAMETERS_ON_ORDER}
  ${ITEM_VALUES_AND_PARAMETERS_ON_ITEM}
  ${DESCRIPTION_ON_EBAY_ORDER}
  ${DESCRIPTION_ON_EBAY_ITEM}
  ${RETURN_ON_EBAY_ITEM}
  ${TITLE_ON_EBAY_ORDER}
  ${TITLE_ON_EBAY_ITEM}
  ${WHITE_GLOVE_ON_EBAY_ORDER}
  ${SUPERVISED_AUTHENTICATION_ON_EBAY_ORDER}
  ${ITEM_CONDITION_ON_EBAY_ORDER}
  ${ITEM_CONDITION_ON_EBAY_ITEM}
  ${IMAGE_URLS_ON_EBAY_ORDER}
  ${IMAGE_URLS_ON_EBAY_ITEM}
  ${SHIPMENT_ON_ORDER}
  ${SHIPMENT_ON_SHIPMENT_BOX}
  ${FAILED_STATION_ITEMS_ON_ITEMS}
  ${QC_STATION_ITEM_ON_ITEM}
  ${AUTH_STATION_ITEM_ON_ITEM}
  ${PACKAGING_COLLATERALS_ON_ITEM}
  ${PARTNER_LOCATION_CONFIGURATIONS}
  ${VaultShipmentBoxOnItem}
  ${RELATED_RETURN_ITEMS}
  ${ORDER_ITEMS}
  ${SHIPMENT_BOX_SCAN_ITEM_BY_LPN}
  mutation scanItemByLpn(
    $lpn: String
    $partnerLocationId: String
    $stationId: ID
    $forQcStation: Boolean!
    $forAuthStation: Boolean!
    $forPackStation: Boolean!
    $forShipStation: Boolean!
    $forAdminStation: Boolean!
    $forTC: Boolean!
    $categoryId: ID
  ) {
    scanItemByLpn(
      lpn: $lpn
      stationId: $stationId
      partnerLocationId: $partnerLocationId
      forAdminStation: $forAdminStation
      categoryId: $categoryId
    ) {
      item {
        id
        tcCardTypes @include(if: $forTC)
        professionalGrader @include(if: $forTC)
        itemIdentifier
        isCustomized
        isMiscategorized
        isAuthenticated
        isForwardAlitheonable @include(if: $forTC)
        isVaultBound @include(if: $forTC)
        isVaultShipping @include(if: $forTC)
        isSport @include(if: $forTC)
        isExport
        itemPrice @include(if: $forTC)
        itemPriceCurrency @include(if: $forTC)
        exportCountry
        isInHandSubmission @include(if: $forTC)
        isHandedToPsa @include(if: $forTC)
        ebayStatus
        nextStationId
        blueBiteCardType
        blueBiteCardScanned
        blueBiteCardPrinting
        blueBiteCardRescanAtShipping
        nfcTag {
          tagUrl
        }
        alitheonStatus @include(if: $forTC)
        itemBranded
        orderSportItemValue @include(if: $forTC)
        allItemImages {
          isAlitheon
          imageUrl
          imagePill
          title
        }
        isReadyForPackaging @include(if: $forPackStation)
        isReturnToSeller @include(if: $forPackStation)
        notAuthenticReadyToBeShipped @include(if: $forPackStation)
        itemIdsToShips @include(if: $forPackStation)
        isPacked @include(if: $forPackStation)
        isPacked @include(if: $forAdminStation)
        ...RelatedReturnItems @include(if: $forPackStation)
        ...OrderItems
        ...AvailableLocations @include(if: $forPackStation)
        ...NotAuthenticLocation @include(if: $forPackStation)
        ...BrandAndModel @include(if: $forAuthStation)
        ...CardValues @include(if: $forPackStation)
        ...CardValues @include(if: $forAdminStation)
        ...StationItemForTimeline @include(if: $forQcStation)
        ...StationItemForTimeline @include(if: $forAuthStation)
        ...StationItemForTimeline @include(if: $forPackStation)
        ...FailedStationItems @include(if: $forQcStation)
        ...FailedStationItems @include(if: $forAuthStation)
        ...FailedStationItems @include(if: $forPackStation)
        ...QcStationItem @include(if: $forQcStation)
        ...QcStationItem @include(if: $forAuthStation)
        ...QcStationItem @include(if: $forPackStation)
        ...AuthStationItem @include(if: $forAuthStation)
        ...PackagingCollaterals @include(if: $forPackStation)
        originalItem {
          id
          lpn {
            id
            lpn
          }
          order {
            id
            evtn
            partnerLocation {
              categoryId
              id
              isAllowedToReprintShippingLabel
              name
              timeZone
            }
          }
        }
        lpn {
          id
          lpn
          createdAt
        }
        category {
          id
          name
          hasCollaterals
        }
        collaterals {
          id
          name
          lpn {
            id
            lpn
            createdAt
          }
        }
        ...ShipmentBoxScanItemByLpn @include(if: $forPackStation)
        ...ShipmentBoxScanItemByLpn @include(if: $forAdminStation)
        ...ShipmentBoxScanItemByLpn @include(if: $forShipStation)
        authenticatorResponses {
          id
          identifier
          itemId
          mode
          originalItemId
          originalItem {
            id
            lpn {
              id
              lpn
            }
          }
          status
          authenticatedAt
        }
        ...ItemValuesAndParameters @include(if: $forQcStation)
        ...ItemValuesAndParameters @include(if: $forAuthStation)
        ...ItemValuesAndParameters @include(if: $forPackStation)
        ebayItem {
          id
          unitId
          itemUrl
          authenticationLevel
          ...EbayItemDescription @include(if: $forQcStation)
          ...EbayItemReturn @include(if: $forQcStation)
          ...EbayItemTitle @include(if: $forQcStation)
          ...EbayItemItemCondition @include(if: $forQcStation)
          ...EbayItemImageUrls @include(if: $forQcStation)
          ...EbayItemImageUrls @include(if: $forAuthStation)
          ...EbayItemImageUrls @include(if: $forPackStation)
          psaMode
          itemPrice
        }
        order {
          id
          status
          isReturn
          evtn
          imageUrls
          itemQuantity
          ebayStatus
          orderSportItemValue @include(if: $forTC)
          status
          isVaultBound @include(if: $forTC)
          isVaultShipping @include(if: $forTC)
          isInHandSubmission @include(if: $forTC)
          gradedListingTc @include(if: $forTC)
          partnerLocation {
            categoryId
            id
            isAllowedToReprintShippingLabel
            name
            timeZone
          }
          ...OrderItemValuesAndParameters @include(if: $forQcStation)
          ...OrderItemValuesAndParameters @include(if: $forAuthStation)
          ...OrderItemValuesAndParameters @include(if: $forPackStation)
          ebayOrder {
            id
            ...EbayOrderDescription @include(if: $forQcStation)
            ...EbayOrderTitle @include(if: $forQcStation)
            ...EbayOrderItemCondition @include(if: $forQcStation)
            ...EbayOrderImageUrls @include(if: $forQcStation)
            ...EbayOrderImageUrls @include(if: $forAuthStation)
            ...EbayOrderImageUrls @include(if: $forPackStation)
            ...WhiteGlove @include(if: $forQcStation)
            ...WhiteGlove @include(if: $forAuthStation)
            ...SupervisedAuthentication @include(if: $forQcStation)
            ...SupervisedAuthentication @include(if: $forAuthStation)
            itemUrl
            authenticationLevel
            signatureRequired
            isReturn
          }
          ...Shipment @include(if: $forShipStation)
          ...Shipment @include(if: $forPackStation)
          ...PartnerLocationConfigurations @include(if: $forQcStation)
        }
      }
      readOnlyStation {
        readOnly
        message
        timestamp
      }
      readOnlyException {
        exceptionAllowed
        message
      }
    }
  }
`;

export const STATION_ITEM_RESULT = gql`
  mutation stationItemResult(
    $stationId: ID!
    $nextStationId: ID!
    $itemId: ID!
    $status: String!
    $grade: String!
    $notes: String
    $isAuthenticated: Boolean
    $stationItemAnswers: [StationItemAnswersAttributes!]
    $updateSv: Boolean
  ) {
    stationItemResult(
      stationId: $stationId
      nextStationId: $nextStationId
      itemId: $itemId
      status: $status
      grade: $grade
      notes: $notes
      isAuthenticated: $isAuthenticated
      stationItemAnswers: $stationItemAnswers
      updateSv: $updateSv
    ) {
      id
      timelineStationStatus
      stationId
      station {
        id
        name
      }
      status
      grade
      createdAt
      updatedAt
      itemId
      item {
        id
        stationId
      }
      notes
      stationItemAnswers {
        id
        inputId
        inputValueId
        notes
        text
        grade
      }
      firstName
      lastName
      userIdentification
      auditCreatedAt
    }
  }
`;

export const FLAG_EXCEPTION = gql`
  mutation flagException($args: FlagExceptionAttributes!) {
    flagException(args: $args) {
      id
    }
  }
`;

export const UPDATE_ASSIGNED_USER = gql`
  mutation updateAssignedUser($args: AssignedUserInputAttributes!) {
    updateAssignedUser(args: $args) {
      id
    }
  }
`;

export const UPDATE_OBJECT_EXCEPTION_OWNER = gql`
  mutation updateObjectPrimaryOwner($id: ID!, $primaryOwnerId: ID!) {
    updateObjectPrimaryOwner(id: $id, primaryOwnerId: $primaryOwnerId) {
      id
    }
  }
`;

export const FETCH_ENTRUPY_SESSION = gql`
  mutation fetchEntrupySession($lpn: String!, $mode: String!, $stationId: ID) {
    fetchEntrupySession(lpn: $lpn, mode: $mode, stationId: $stationId) {
      activity {
        mode
        name
      }
      entrupyId
      fingerprintParent {
        activity {
          mode
          name
        }
        entrupyId
        textFields {
          customerItemId
        }
      }
      status {
        flag
        result {
          display
          final
          id
        }
      }
      textFields {
        customerItemId
      }
      timestamp {
        display
      }
    }
  }
`;

export const STATION_ITEMS_FOR_ORDER = gql`
  mutation stationItemsForOrder(
    $status: String!
    $grade: String!
    $notes: String
    $stationId: ID!
    $nextStationId: ID!
    $orderId: ID!
    $updateSv: Boolean
  ) {
    stationItemsForOrder(
      status: $status
      grade: $grade
      notes: $notes
      stationId: $stationId
      nextStationId: $nextStationId
      orderId: $orderId
      updateSv: $updateSv
    ) {
      id
      status
      grade
      notes
      completedAt
    }
  }
`;

export const ASSOCIATE_EVTN = gql`
  mutation associateEvtn($args: AssociateEvtnInputAttributes!) {
    associateEvtn(args: $args) {
      id
      gcxTicketNumber
      exceptionStatus
      order {
        id
        evtn
        trackings {
          id
          trackingNumber
        }
        items {
          id
          lpn {
            id
            lpn
          }
        }
        partnerLocation {
          id
          timeZone
        }
      }
    }
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation createShipment($lpn: String!) {
    createShipment(lpn: $lpn) {
      id
      trackingNumber
      carrier {
        id
        name
      }
    }
  }
`;

export const CREATE_SHIPMENT_BOX = gql`
  mutation createShipment(
    $lpn: String!
    $boxId: ID
    $dustBagId: ID
    $shipmentBoxId: ID
  ) {
    createShipmentBox(
      lpn: $lpn
      boxId: $boxId
      dustBagId: $dustBagId
      shipmentBoxId: $shipmentBoxId
    ) {
      id
    }
  }
`;

export const CREATE_SHIPMENT_BAG = gql`
  mutation createShipmentBag($partnerLocationId: ID!) {
    createShipmentBag(partnerLocationId: $partnerLocationId) {
      id
    }
  }
`;

export const SHIPPING_COMBINED_SECOND = gql`
  mutation shippingCombinedSecond($lpn: String!) {
    shippingCombinedSecond(lpn: $lpn) {
      id
    }
  }
`;

export const SCAN_ITEM_AT_SHIPPING = gql`
  mutation shippingCombinedFirst($lpn: String!, $partnerLocationId: String) {
    shippingCombinedFirst(lpn: $lpn, partnerLocationId: $partnerLocationId) {
      item {
        id
        category {
          id
          name
        }
        nextStationId
        nextStation {
          id
          name
        }
        isShipped
        stationItems {
          updatedAt
          completedAt
          firstName
          lastName
          grade
          userIdentification
          createdAt
          station {
            id
            name
          }
        }
      }
      shipmentBox {
        id
        trackingNumber
        shipment {
          id
          image
          deliveryDate
          allShipped
          createdByException
          address {
            id
            streetLine1
            streetLine2
            city
            stateProvinceCode
            country
            postalCode
          }
          shipmentBoxes {
            id
          }
          carrier {
            id
            name
            image
          }
          order {
            id
            ebayStatus
            partnerLocation {
              id
              name
              isAllowedToReprintShippingLabel
            }
            orderExceptions {
              id
              exceptionVerdict
              createdBy {
                id
                userIdentification
              }
              exceptionCode {
                id
                name
              }
              createdAt
              notes {
                id
                description
                firstName
                lastName
                userIdentification
                createdAt
                filesInfo {
                  filename
                  url
                }
              }
            }
            ebayOrder {
              id
              isReturn
            }
          }
        }
        items {
          id
          isI
          isExport
          exportCountry
          isInHandSubmission
          lpn {
            id
            lpn
          }
          ebayItem {
            id
            unitId
            signatureRequired
          }
          order {
            ebayOrder {
              id
              signatureRequired
            }
          }
          shipmentBox {
            shippingLabels {
              isPhysical
            }
          }
        }
        box {
          id
          boxSize
        }
        dustBag {
          id
          dustBagSize
        }
        shippingLabels {
          id
          zplString
          isPhysical
        }
      }
      readOnlyStation {
        readOnly
        message
        timestamp
      }
    }
  }
`;

export const CREATE_PRIVATE_NOTES = gql`
  mutation createPrivateNotes(
    $orderId: ID!
    $itemId: ID
    $note: String!
    $stationId: ID!
  ) {
    createPrivateNotes(
      orderId: $orderId
      itemId: $itemId
      note: $note
      stationId: $stationId
    ) {
      id
    }
  }
`;

export const UPLOAD_ITEM_DATA_TO_BLUE_BITE = gql`
  mutation uploadItemDataToBlueBite(
    $id: ID!
    $blueBiteCardId: String
    $tagUrl: String!
    $cardType: String!
    $stationId: ID
    $isUpdateNewQr: Boolean
  ) {
    uploadItemDataToBlueBite(
      id: $id
      blueBiteCardId: $blueBiteCardId
      tagUrl: $tagUrl
      cardType: $cardType
      stationId: $stationId
      isUpdateNewQr: $isUpdateNewQr
    ) {
      id
    }
  }
`;
export const QUERY_INPUT_VALUES_WITH_PARENT = gql`
  ${INPUT_VALUE_FRAGMENT}
  mutation queryInputValuesWithParent($parentId: ID!) {
    queryInputValuesWithParent(parentId: $parentId) {
      ...InputValueFragment
    }
  }
`;

export const ADD_INPUT_VALUE = gql`
  ${INPUT_VALUE_FRAGMENT}
  mutation addInputValue(
    $value: String!
    $parentId: ID
    $inputId: ID!
    $inputParentId: ID
    $isVaultBound: Boolean
    $partnerLocationId: ID
  ) {
    addInputValue(
      value: $value
      parentId: $parentId
      inputId: $inputId
      inputParentId: $inputParentId
      isVaultBound: $isVaultBound
      partnerLocationId: $partnerLocationId
    ) {
      ...InputValueFragment
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote(
    $description: String!
    $notableType: String!
    $notableId: ID!
    $stationId: ID!
    $visibilities: [String!]!
  ) {
    createNote(
      description: $description
      notableType: $notableType
      notableId: $notableId
      stationId: $stationId
      visibilities: $visibilities
    ) {
      id
    }
  }
`;

export const CREATE_AND_UPDATE_COLLATERAL = gql`
  mutation createAndUpdateCollateral($input: CollateralInput!) {
    createAndUpdateCollateral(input: $input) {
      id
      lpnId
      lpn {
        lpn
      }
      itemId
    }
  }
`;

export const RESOLVE_NO_SHIPPING_REQUIRED = gql`
  mutation resolveNoShippingRequired($args: NoShipmentRequiredAttributes!) {
    resolveNoShippingRequired(args: $args) {
      id
      exceptionStatus
    }
  }
`;

export const RESOLVE_RESHIP = gql`
  mutation resolveReship($args: ResolveReshipAttributes!) {
    resolveReship(args: $args) {
      id
      exceptionStatus
      __typename
    }
  }
`;

export const RESOLVE_DISCARD_EXCEPTION = gql`
  mutation resolveDiscardException($args: DiscardExceptionAttributes!) {
    resolveDiscardException(args: $args) {
      id
      exceptionStatus
    }
  }
`;

export const RESOLVE_NO_ACTION_REQUIRED = gql`
  mutation resolveNoActionRequired($args: NoActionRequiredInputAttributes!) {
    resolveNoActionRequired(args: $args) {
      id
      exceptionStatus
    }
  }
`;

export const DELETE_ASSOCIATE_SHIPPING_LABEL = gql`
  mutation deleteAssociateShippingLabel($args: ShippingLabelAttributes!) {
    deleteAssociateShippingLabel(args: $args) {
      id
    }
  }
`;
export const GIVE_A_VERDICT = gql`
  mutation giveAVerdict($args: VerdictInputAttributes!) {
    giveAVerdict(args: $args) {
      id
      exceptionStatus
    }
  }
`;
export const RESOLVE_EXCEPTION = gql`
  mutation resolveException($args: ResolverExceptionInputAttributes!) {
    resolveException(args: $args) {
      id
      exceptionStatus
    }
  }
`;
export const UPDATE_EXCEPTION_NOTE = gql`
  mutation updateExceptionNote($args: UpdateNoteInputAttributes!) {
    updateExceptionNote(args: $args) {
      id
    }
  }
`;

export const ASSOCIATE_EXCEPTION_SHIPMENT = gql`
  mutation associateExceptionShipment(
    $args: ExceptionShipmentInputAttributes!
  ) {
    associateExceptionShipment(args: $args) {
      id
      exceptionStatus
    }
  }
`;

export const RESOLVE_OBJECT_LOCATION = gql`
  mutation resolveObjectLocation(
    $exceptionId: String!
    $locationIdentifier: String!
    $partnerLocationId: String!
    $action: String!
  ) {
    resolveObjectLocation(
      exceptionId: $exceptionId
      locationIdentifier: $locationIdentifier
      partnerLocationId: $partnerLocationId
      action: $action
    ) {
      id
      actualLocations {
        id
        identifier
      }
    }
  }
`;

export const UPDATE_GCX_TICKET_NUMBER = gql`
  mutation updateGcxTicketNumber(
    $id: ID!
    $gcxTicketNumber: String!
    $gcxTicketUrl: String!
  ) {
    updateGcxTicketNumber(
      id: $id
      gcxTicketNumber: $gcxTicketNumber
      gcxTicketUrl: $gcxTicketUrl
    ) {
      id
      gcxTicketNumber
      gcxTicketUrl
    }
  }
`;

export const USER_ACCESS_MANAGEMENT = gql`
  mutation userAccessManagement($id: ID!, $action: String!) {
    userAccessManagement(id: $id, action: $action) {
      id
      isLocked
      isInactivated
    }
  }
`;

export const SEND_RESET_PW_EMAILS = gql`
  mutation sendResetPwEmails($email: String!) {
    sendResetPwEmails(email: $email) {
      email
      isLocked
      isInactivated
    }
  }
`;

export const RESET_2FA = gql`
  mutation reset2fa {
    reset2fa
  }
`;

export const DISPATCH_MANIFESTS = gql`
  mutation dispatchManifest($input: ReportsFiltersInput!) {
    dispatchManifest(input: $input) {
      filename
      url
    }
  }
`;

export const AUTHENTICATION_REPORTS = gql`
  mutation authReport($input: ReportsFiltersInput!) {
    authReport(input: $input) {
      filename
      url
    }
  }
`;

export const SLA_REPORTS = gql`
  mutation slaReport($input: ReportsFiltersInput!) {
    slaReport(input: $input) {
      filename
      url
    }
  }
`;

export const SHIPPING_REPORTS = gql`
  mutation shippingReport($input: ReportsFiltersInput!) {
    shippingReport(input: $input) {
      filename
      url
    }
  }
`;

export const PACKAGING_REPORTS = gql`
  mutation packagingReport($input: ReportsFiltersInput!) {
    packagingReport(input: $input) {
      filename
      url
    }
  }
`;

export const BILLING_REPORTS = gql`
  mutation billingReport($input: ReportsFiltersInput!, $fileType: String!) {
    billingReport(input: $input, fileType: $fileType) {
      filename
      url
    }
  }
`;

export const AD_HOC_REPORTS = gql`
  mutation adHocReport(
    $input: ReportsFiltersInput!
    $selectedFields: [String!]!
    $completionStatus: String!
  ) {
    adHocReport(
      input: $input
      selectedFields: $selectedFields
      completionStatus: $completionStatus
    ) {
      filename
      url
    }
  }
`;

export const PACE_REPORTS = gql`
  mutation paceReport($input: ReportsFiltersInput!, $fileType: String!) {
    paceReport(input: $input, fileType: $fileType) {
      filename
      url
    }
  }
`;

export const UPH_REPORT = gql`
  mutation uphReport($input: ReportsFiltersInput!, $fileType: String!) {
    uphReport(input: $input, fileType: $fileType) {
      filename
      url
    }
  }
`;

export const CREATE_MANIFEST = gql`
  mutation createManifest(
    $selectedCourier: String!
    $partnerLocationId: ID!
    $shippingDate: String
  ) {
    createManifest(
      selectedCourier: $selectedCourier
      partnerLocationId: $partnerLocationId
      shippingDate: $shippingDate
    ) {
      id
    }
  }
`;

export const ADD_ITEM_TO_SHIPMENT_BOX = gql`
  ${VaultShipmentBoxOnItem}
  mutation addItemToShipmentBox($itemLpn: String!, $shipmentBoxLpn: String!) {
    addItemToShipmentBox(itemLpn: $itemLpn, shipmentBoxLpn: $shipmentBoxLpn) {
      ...vaultShipmentBoxOnItem
    }
  }
`;

export const CREATE_PDF_GUIDE = gql`
  mutation createPdfGuide($args: CreatePdfGuideAttributes!) {
    createPdfGuide(args: $args) {
      id
    }
  }
`;

export const FILTER_ENTRUPY_DATA = gql`
  mutation filterEntrupyData($input: EntrupyDataFiltersInput!) {
    filterEntrupyData(input: $input) {
      id
      name
      lpn {
        id
        lpn
      }
      authenticatorResponses {
        id
        status
        authenticatedAt
        entrupyPdf {
          filename
          url
        }
        entrupyZip {
          filename
          url
        }
      }
    }
  }
`;

export const CREATE_DISPATCH_SCAN_STATION_ITEM = gql`
  mutation createDispatchScanStationItem($tracking: String!) {
    createDispatchScanStationItem(tracking: $tracking) {
      tracking
      completedAt
    }
  }
`;

export const UPDATE_ITEM_ALITHEON_STATUS = gql`
  mutation updateItemAlitheonStatus($itemId: ID!, $status: String!) {
    updateItemAlitheonStatus(itemId: $itemId, status: $status) {
      id
      alitheonStatus
    }
  }
`;

export const UPLOAD_ITEM_IMAGES = gql`
  ${ALL_ITEM_IMAGES}
  mutation uploadItemImages(
    $itemId: ID!
    $objectExceptionId: ID
    $attachments: [UploadTypedImageAttributes!]!
    $type: String
    $uploadToZoomApi: Boolean
  ) {
    uploadItemImages(
      itemId: $itemId
      objectExceptionId: $objectExceptionId
      attachments: $attachments
      type: $type
      uploadToZoomApi: $uploadToZoomApi
    ) {
      id
      ...AllItemImages
      lpn {
        id
        lpn
      }
    }
  }
`;

export const DELETE_ITEM_IMAGE = gql`
  ${ALL_ITEM_IMAGES}
  mutation deleteItemImage($itemImageId: ID!) {
    deleteItemImage(itemImageId: $itemImageId) {
      id
      ...AllItemImages
    }
  }
`;

export const CHANGE_INTERCEPTION_FLAG = gql`
  mutation changeInterceptionFlag($orderId: ID!, $interceptionFlag: Boolean!) {
    changeInterceptionFlag(
      orderId: $orderId
      interceptionFlag: $interceptionFlag
    ) {
      id
      interceptionFlag
    }
  }
`;

export const ADD_ITEM_IN_A_BOX = gql`
  mutation addItemInTheBox($id: ID!, $lpn: String!) {
    addItemInTheBox(id: $id, lpn: $lpn) {
      id
      nfcTag {
        tagUrl
      }
      ebayStatus
      itemBrand
      itemMaterial
      itemStyle
      itemModel
      itemReferenceNo
      itemSerialNo
      itemCaseMaterial
      itemMovementType
      itemBracelet
      itemAuthenticationDate
      authenticatedDate
      isPacked
      lpn {
        id
        lpn
      }
      category {
        id
        name
        hasCollaterals
      }
      order {
        id
        ebayStatus
        isReturn
      }
      blueBiteCardType
      blueBiteCardScanned
      blueBiteCardPrinting
      blueBiteCardRescanAtShipping
      blueBiteCardRescan
    }
  }
`;

export const ADD_ITEM_IN_A_LOCATION = gql`
  mutation addItemInALocation($id: ID!, $identifier: String!) {
    addItemInALocation(id: $id, identifier: $identifier) {
      id
    }
  }
`;

export const UPDATE_ADMIN_PANEL_USER_STATION = gql`
  mutation updateAdminPanelUserStation(
    $ids: [ID!]!
    $addStationsIds: [ID!]!
    $removeStationsIds: [ID!]!
  ) {
    updateAdminPanelUserStation(
      ids: $ids
      addStationsIds: $addStationsIds
      removeStationsIds: $removeStationsIds
    ) {
      id
      stations {
        id
      }
    }
  }
`;

export const UPDATE_ADMIN_PANEL_USER_CATEGORY = gql`
  mutation updateAdminPanelUserCategory(
    $ids: [ID!]!
    $addPartnerLocationsIds: [ID!]!
    $removeCategoriesIds: [ID!]!
  ) {
    updateAdminPanelUserCategory(
      ids: $ids
      addPartnerLocationsIds: $addPartnerLocationsIds
      removeCategoriesIds: $removeCategoriesIds
    ) {
      id
      categories {
        id
      }
    }
  }
`;

export const BULK_UPDATE_ADMIN_PANEL_USER = gql`
  mutation bulkUpdateAdminPanelUser($ids: [ID!]!, $bulkAction: String!) {
    bulkUpdateAdminPanelUser(ids: $ids, bulkAction: $bulkAction) {
      id
      isLocked
      isInactivated
      updatedAt
    }
  }
`;

export const UPDATE_USER_GROUP_RULE = gql`
  mutation updateUserGroupRule(
    $id: ID!
    $partnerLocationsIds: [ID!]
    $stationsIds: [ID!]
    $categoriesIds: [ID!]
  ) {
    updateUserGroupRule(
      id: $id
      partnerLocationsIds: $partnerLocationsIds
      stationsIds: $stationsIds
      categoriesIds: $categoriesIds
    ) {
      id
      name
      role {
        id
      }
      partnerLocations {
        id
        name
      }
      categories {
        id
        name
      }
      stations {
        id
        name
      }
    }
  }
`;

export const UPDATE_QR_CODE = gql`
  mutation updateQrCode($qrTag: String!, $lpn: String!) {
    updateQrCode(qrTag: $qrTag, lpn: $lpn) {
      name
      description
    }
  }
`;

export const UPLOAD_IHS_ORDER_DATA = gql`
  mutation uploadIhsOrderData(
    $orderId: ID!
    $blueBiteCardId: String
    $tagUrl: String!
    $cardType: String!
    $stationId: ID
  ) {
    uploadIhsOrderData(
      orderId: $orderId
      blueBiteCardId: $blueBiteCardId
      tagUrl: $tagUrl
      cardType: $cardType
      stationId: $stationId
    ) {
      id
    }
  }
`;

export const PSNAD_STATION_ITEM_RESULT = gql`
  mutation psnadStationItemResult(
    $objectExceptionId: ID!
    $psnadInputIds: [ID!]!
  ) {
    psnadStationItemResult(
      objectExceptionId: $objectExceptionId
      psnadInputIds: $psnadInputIds
    ) {
      id
      stationItemAnswers {
        input {
          id
          snadCode
          inputType
        }
      }
    }
  }
`;

export const SUBMIT_PSNAD = gql`
  ${PSNAD_EXCEPTION}
  mutation submitPsnad($objectExceptionId: ID!) {
    submitPsnad(objectExceptionId: $objectExceptionId) {
      ...PsnadException
    }
  }
`;

export const RESOLVE_PSNAD = gql`
  ${PSNAD_EXCEPTION}
  mutation resolvePsnad(
    $objectExceptionId: ID!
    $resolveType: PsnadResolveTypeEnum!
    $note: String!
    $shouldRemoveLocation: Boolean
  ) {
    resolvePsnad(
      objectExceptionId: $objectExceptionId
      resolveType: $resolveType
      note: $note
      shouldRemoveLocation: $shouldRemoveLocation
    ) {
      id
      exceptionStatus
      psnadException {
        ...PsnadException
      }
    }
  }
`;

export const UPDATE_HUB_SERVICE_GROUP = gql`
  mutation updateHubServiceGroup(
    $id: ID
    $partnerLocationId: ID
    $mondayPickupTime: String
    $tuesdayPickupTime: String
    $wednesdayPickupTime: String
    $thursdayPickupTime: String
    $fridayPickupTime: String
    $saturdayPickupTime: String
    $sundayPickupTime: String
    $isMondayActive: Boolean!
    $isTuesdayActive: Boolean!
    $isWednesdayActive: Boolean!
    $isThursdayActive: Boolean!
    $isFridayActive: Boolean!
    $isSaturdayActive: Boolean!
    $isSundayActive: Boolean!
    $dailyPhotoRobotImagingCapacity: String
  ) {
    updateHubServiceGroup(
      id: $id
      partnerLocationId: $partnerLocationId
      mondayPickupTime: $mondayPickupTime
      tuesdayPickupTime: $tuesdayPickupTime
      wednesdayPickupTime: $wednesdayPickupTime
      thursdayPickupTime: $thursdayPickupTime
      fridayPickupTime: $fridayPickupTime
      saturdayPickupTime: $saturdayPickupTime
      sundayPickupTime: $sundayPickupTime
      isMondayActive: $isMondayActive
      isTuesdayActive: $isTuesdayActive
      isWednesdayActive: $isWednesdayActive
      isThursdayActive: $isThursdayActive
      isFridayActive: $isFridayActive
      isSaturdayActive: $isSaturdayActive
      isSundayActive: $isSundayActive
      dailyPhotoRobotImagingCapacity: $dailyPhotoRobotImagingCapacity
    ) {
      id
      isActive
      mondayPickupTime
      tuesdayPickupTime
      wednesdayPickupTime
      thursdayPickupTime
      fridayPickupTime
      saturdayPickupTime
      sundayPickupTime
      serviceGroup {
        id
        name
      }
      createdAt
      updatedAt
      overridePickupTs
      nextDispatchTime
    }
  }
`;

export const UPDATE_HUB_SERVICE_GROUP_OVERRIDE_DATE = gql`
  mutation updateHubServiceGroupOverrideDate(
    $id: ID!
    $overridePickupTs: ISO8601DateTime!
  ) {
    updateHubServiceGroupOverrideDate(
      id: $id
      overridePickupTs: $overridePickupTs
    ) {
      id
      isActive
      mondayPickupTime
      tuesdayPickupTime
      wednesdayPickupTime
      thursdayPickupTime
      fridayPickupTime
      saturdayPickupTime
      sundayPickupTime
      serviceGroup {
        id
        name
      }
      createdAt
      updatedAt
      overridePickupTs
      nextDispatchTime
    }
  }
`;

export const RESET_LPN = gql`
  mutation resetLpn($lpn: String!) {
    resetLpn(lpn: $lpn) {
      id
    }
  }
`;

export const SAVE_STATION_ITEM_ANSWERS = gql`
  mutation saveStationItemAnswers($answers: [AnswersAttributes!]!) {
    saveStationItemAnswers(answers: $answers) {
      id
    }
  }
`;

export const IMAGING_SERVICES = gql`
  mutation imageServices($service: String!, $action: String!, $lpn: String!) {
    imageServicesResponse(service: $service, action: $action, lpn: $lpn) {
      status
      message
    }
  }
`;
