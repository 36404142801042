import { getRequest } from './httpClient';
import { getErrorCode } from './errorsCode';
import { GET_PENDING_PICKS_PATH } from './routes';

const pageNumber = 0;
const pageSize = 2;

async function getPendingPicks(hubId) {
  const searchParams = new URLSearchParams();

  searchParams.set('pageNumber', pageNumber.toString());
  searchParams.set('pageSize', pageSize.toString());
  searchParams.set('hubId', hubId);

  const response = await getRequest(
    `${GET_PENDING_PICKS_PATH}?${searchParams}`
  );

  if (response.error) {
    return {
      data: null,
      error: { code: getErrorCode(response.error.statusCode) },
    };
  }
  if (response.data) {
    return {
      data: {
        totalSize: response.data.totalSize,
      },
    };
  }

  return {
    data: {
      totalSize: 0,
    },
  };
}

export default getPendingPicks;
