import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import getPendingPicks from '../../../../api/rest/picks';
import useFulfillmentEnabled from '../../../../hooks/useFulfillmentEnabled';
import { useStateValue } from '../../../../providers/StateProvider';

export default function usePicks() {
  const [hasPendingPicks, setHasPendingPicks] = useState(false);
  const { fulfillmentEnabled } = useFulfillmentEnabled();
  const [{ partnerLocation }] = useStateValue();

  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['picks'],
    queryFn: () => getPendingPicks(partnerLocation.id),
    cacheTime: 0,
    enabled: fulfillmentEnabled,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data?.data?.totalSize) {
        setHasPendingPicks(data?.data?.totalSize > 0);
      }
    }
  }, [isSuccess, isLoading, data]);

  return { hasPendingPicks };
}
