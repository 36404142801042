/* eslint-disable react/prop-types */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  throwServerError,
} from '@apollo/client';
import React from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client/public/index';
import { GlobalLoadingIndicator } from './GlobalLoadingIndicator';
import { LoadingProvider } from '../../context/LoadingContext';
import { ApolloContextProvider } from './context/ApolloContext';

const { useApolloNetworkStatus, link: networkStatusLink } =
  createNetworkStatusNotifier();

const { AppAPIDomain } = process.env;

const customFetch = (uri, options) => {
  return fetch(uri, options).then((response) => {
    if (response.status > 400) {
      return Promise.reject(throwServerError(response, false, 'fail'));
    }
    return response;
  });
};

export const getAPIUri = () => {
  if (window.location.href.includes('---')) {
    const url = window.location.host.split('---');
    const tag = url[0];
    return AppAPIDomain.replace('api-', `${tag}---api-`);
  }
  return AppAPIDomain;
};

const getClient = () => {
  const httpLink = ApolloLink.from([
    createUploadLink({
      uri: `${getAPIUri()}/graphql?`,
      headers: {
        authorization: `Bearer ${sessionStorage.authToken}`,
      },
      fetch: customFetch,
    }),
  ]);

  const administrationHttpLink = createHttpLink({
    uri: `${getAPIUri()}/api/admin/graphql`,
    headers: {
      authorization: `Bearer ${sessionStorage.authToken}`,
    },
    fetch: customFetch,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from session storage if it exists
    const token = sessionStorage.getItem('authToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === 'administration',
    authLink.concat(networkStatusLink.concat(administrationHttpLink)),
    authLink.concat(networkStatusLink.concat(httpLink))
  );

  return new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache(),
  });
};

const CustomApolloProvider = ({ children }) => {
  return (
    <ApolloContextProvider useApolloNetworkStatus={useApolloNetworkStatus}>
      <ApolloProvider client={getClient()}>
        <LoadingProvider>
          <GlobalLoadingIndicator
            useApolloNetworkStatus={useApolloNetworkStatus}
          />
          {children}
        </LoadingProvider>
      </ApolloProvider>
    </ApolloContextProvider>
  );
};

export default CustomApolloProvider;
