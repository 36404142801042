import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, List, Collapse } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import MenuElement from './reusables/menu/menuElement/MenuElement';
import { useStateValue } from '../providers/StateProvider';
import removeSpace from '../utils/removeSpace';
import { CURRENT_PARTNER_LOCATION_CONFIGS } from '../graphql/queries';
import makeError from '../utils/makeError';

const HubSwitch = (props) => {
  const { partnerLocations, toggleDrawer } = props;
  const { t } = useTranslation();

  const [contextVariables, dispatch] = useStateValue();
  const { currentStationId, partnerLocation } = contextVariables;

  const [selectedPartnerLocationId, setSelectedPartnerLocationId] = useState(
    partnerLocation?.id || ''
  );

  const [fetchCurrentPartnerLocationConfigs] = useLazyQuery(
    CURRENT_PARTNER_LOCATION_CONFIGS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ currentPartnerLocationConfigs }) => {
        sessionStorage.setItem(
          'partnerLocationConfigs',
          JSON.stringify({
            fingerprintMethods:
              currentPartnerLocationConfigs.fingerprintMethods,
            isTitleHeaderConfigOn:
              currentPartnerLocationConfigs.isTitleHeaderConfigOn,
          })
        );
        dispatch({
          type: 'changeCurrentPartnerLocationConfigs',
          currentPartnerLocationConfigs: {
            fingerprintMethods:
              currentPartnerLocationConfigs.fingerprintMethods,
            isTitleHeaderConfigOn:
              currentPartnerLocationConfigs.isTitleHeaderConfigOn,
          },
        });
      },
      onError: (error) => makeError(error, dispatch, t),
    }
  );

  const handleChange = (item) => {
    setSelectedPartnerLocationId(item.id);
    const {
      name,
      hubId,
      category,
      lpnType,
      timeZone,
      shippingService,
      manifestableCarriers,
      internationalizationCountryCode,
      userPartnerLocationStations,
      hasPsnadStation,
      alitheonRegistrationStation,
      alitheonAuthenticationStation,
      defaultCaseTypeToRaw,
      qcDefaultValues,
      isAllowedToReprintShippingLabel,
      blueBiteAuthenticCardAllowedStatuses,
      inputMaxLength,
    } = partnerLocations.find((x) => x.id === item.id);

    const newPartnerLocation = {
      id: item.id,
      name,
      hubId,
      categoryName: category?.name,
      categoryId: category?.id,
      lpnType,
      timeZone,
      hasManifests: shippingService.hasManifests,
      manifestableCarriers,
      internationalizationCountryCode,
      userPartnerLocationStations,
      hasPsnadStation,
      alitheonRegistrationStation,
      alitheonAuthenticationStation,
      defaultCaseTypeToRaw,
      qcDefaultValues,
      isAllowedToReprintShippingLabel,
      blueBiteAuthenticCardAllowedStatuses,
      inputMaxLength,
    };

    fetchCurrentPartnerLocationConfigs({
      variables: { partnerLocationId: item.id },
    });

    sessionStorage.setItem(
      'partnerLocation',
      JSON.stringify(newPartnerLocation)
    );

    dispatch({
      type: 'changePartnerLocation',
      newPartnerLocation,
    });
    // TODO: Put this section in a reusable

    const userPartnerStations =
      newPartnerLocation.userPartnerLocationStations || [];

    dispatch({
      type: 'changeUserStationList',
      newUserStationList: userPartnerStations.map((station) => ({
        id: station.id,
        name: removeSpace(station.name),
        customEndpoint: station.customEndpoint,
        isExternalUrl: station.isExternalUrl,
      })),
    });
  };
  const [openList, setOpenList] = useState(true);

  const handleClick = () => {
    setOpenList(!openList);
  };
  if (currentStationId)
    return (
      <HubSwitchStyled
        component="nav"
        value={selectedPartnerLocationId}
        selectedPartnerLocationId={currentStationId}
      >
        <MenuElement
          button
          onClick={handleClick}
          text={t('PartnerLocationSelectionContainer.SelectHub')}
          id="select-hub"
          to="#"
          dataTestId="select-hub"
        >
          {!openList ? <RemoveIcon /> : <AddIcon />}
        </MenuElement>
        <Collapse in={!openList} timeout="auto" unmountOnExit>
          {[...partnerLocations]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <MenuElement
                button
                selected={selectedPartnerLocationId === item.id}
                to="#"
                onClick={() => {
                  handleChange(item);
                  toggleDrawer();
                }}
                key={item.id}
                text={item.name}
                id={item.id}
                dataTestId={item.id}
              />
            ))}
        </Collapse>
      </HubSwitchStyled>
    );
  return (
    <FormControl
      style={{
        width: 'fit-content',
        minWidth: '200px',
        height: '50px',
      }}
    >
      <ModifiedSelect
        displayEmpty
        id="select-partner-location"
        data-testid="select-partner-location"
        value={selectedPartnerLocationId}
        autoWidth
        variant="outlined"
      >
        <MenuItem value="" disabled>
          {t('PartnerLocationSelectionContainer.SelectHub')}
        </MenuItem>
        {[...partnerLocations]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <MenuItem
              id={item.id}
              data-testid={item.id}
              dat
              key={item.id}
              value={item.id}
              onClick={() => handleChange(item)}
            >
              {item.name}
            </MenuItem>
          ))}
      </ModifiedSelect>
    </FormControl>
  );
};

export default HubSwitch;
HubSwitch.defaultProps = {
  toggleDrawer: undefined,
};

HubSwitch.propTypes = {
  partnerLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }).isRequired
  ).isRequired,
  toggleDrawer: PropTypes.func,
};

// Style
const HubSwitchStyled = styled(List)`
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  & a:first-of-type {
    margin-top: 0;
  }
`;

export const ModifiedSelect = styled(Select)`
  border: none !important;
  font-family: 'Montserrat', sans-serif !important;
  height: 50px !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  & > div {
    padding: 15.5px 15px;
  }
  & .MuiListItem-root {
    color: blue;
  }
`;
