import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const { AppAPIDomain } = process.env;

export const getAPIUri = () => {
  if (window.location.href.includes('---')) {
    const url = window.location.host.split('---');
    const tag = url[0];
    return AppAPIDomain.replace('api-', `${tag}---api-`);
  }

  return AppAPIDomain;
};

const httpLink = ApolloLink.from([
  createUploadLink({
    uri: `${getAPIUri()}/graphql?`,
    headers: {
      authorization: `Bearer ${sessionStorage.authToken}`,
    },
  }),
]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from session storage if it exists
  const token = sessionStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
